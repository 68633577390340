import React, { useState } from "react"
import PropTypes from "prop-types"
// Components
import Menu from "../Menu"
import Divider from "../Divider/index"
import { SiteHeader, SiteNavigation, StyledWrapper } from './style';
// Theme
//import { useScroll } from '../../global/custom-hooks/useScroll';
import useDocumentScrollThrottled from '../../global/custom-hooks/useDocumentScrollThrottled';
import { Wrapper } from "../../global/grid"

const Header = ({ openNav, setOpenNav, themeSettings, siteTitle, toggleTheme, theme }) => {

  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [shouldShowBackground, setShouldShowBackground] = useState(false);

  const MINIMUM_SCROLL = 100;
  const TIMEOUT_DELAY = 20;

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setShouldShowBackground(currentScrollTop > 100);

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const hasBackground = shouldShowBackground;
  const isScrolled = shouldHideHeader;

 //console.log(bodyOffset)

  return (
    <StyledWrapper>
      <SiteHeader isScrolled={isScrolled} hasBackground={hasBackground}>
          <SiteNavigation isScrolled={isScrolled}>
            <Menu 
              siteTitle={siteTitle}
              openNav={openNav}
              setOpenNav={setOpenNav}
              themeSettings={themeSettings}
              toggleTheme={toggleTheme}
              theme={theme}
              isScrolled={isScrolled}
            />
          <Wrapper style={{ height: 'auto', padding: '0' }}>
            <Divider/>
          </Wrapper>
          </SiteNavigation>
      </SiteHeader>
    </StyledWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header