import React, { Fragment } from 'react';
// Atoms
import { Icon } from '../../atoms/icon/Icon';
import { Heading } from '../../atoms/typography/headings'
import { Card } from '../../atoms/card/Card'
// Theme
import { StyledContainer, StyledRow, StyledButton } from './style'

/**
 * Takes: 
 * @param { addCallout, calloutTitle, calloutLinks, calloutLink }
 * 
 * - addCallout to enable callout
 * - calloutTitle
 * - calloutLinks is a repeater with 2 max calloutLink buttons
 */

const Callout = ({ addCallout, calloutTitle, calloutLinks }) => {
    //console.log(calloutLinks);
    return (
        <Fragment>
            { addCallout && ((
                <StyledContainer>
                    <Card isSecondaryFilledCard={true}>
                        <Heading isH4={true}>{calloutTitle}</Heading>
                        <StyledRow>
                            {calloutLinks.map((link, index) => (
                                <StyledButton key={index} href={link.calloutLink.url} containsIcon={true} isDarkButton={true}>{link.calloutLink.title}<Icon icon="chevronright" size="14px"/></StyledButton>
                            ))}
                        </StyledRow>
                    </Card>
                </StyledContainer>
            ))}
        </Fragment>
    );
}

export default Callout;
