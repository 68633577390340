import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// Components
import UniversalLink from '../../components/UniversalLink.js';
// Global
import { flexUnit } from '../../global/mixin.js';
import { color, typography, elevate, device } from '../../global/theme';
import { easing, jiggle } from '../../global/animations.js';

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

const ButtonText = styled.span`
  white-space: pre-line;
  user-select: none;
  text-transform: ${ typography.transform.uppercase };
  font-size: ${props => props.size === SIZES.SMALL ? flexUnit(1, 0, 8, 'vw', 'font-size') : flexUnit(3, 8, 16, 'vw', 'font-size')};
  font-family: ${ typography.type.primary };
  font-weight: ${ typography.weight.extrabold };
  font-display: fallback;
  text-decoration: none;
  text-align: center;
  letter-spacing: 1.4px;

  @media ${device.mobileL} {
    white-space: nowrap;
  }
`;

const StyledButton = styled(UniversalLink)`
  position: relative;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  padding: 1rem 1rem;
  padding: ${props => props.size === SIZES.SMALL ? '0.5rem 0.5rem' : '1rem 1rem'};
  border-radius: 8px;
  transition: all ${easing.themeChoice};

  &:hover  {
    box-shadow: ${elevate.elevate1};
    transform: translate(2px, 2px);

      svg {
        animation-name: ${jiggle};
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }

    &:active {
      svg {
        animation-name: ${jiggle};
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }

    &:focus {
      svg {
        animation-name: ${jiggle};
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }

    &:focus:hover {
      svg {
        animation-name: ${jiggle};
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }

  svg {
    vertical-align: top;
    margin-top: 2px;
    margin-left: 0.75rem;
    /* Necessary for js mouse events to not glitch out when hovering on svgs */
    pointer-events: none;
  }

  ${props =>
    props.disabled &&
    `
      cursor: not-allowed !important;
      opacity: 0.5;
      &:hover {
        transform: none;
      }
  `}

${props =>
    props.isTextOnly &&
    `
      padding: 0;
  `}
  
  ${props =>
    props.isUnclickable &&
    `
      cursor: default !important;
      pointer-events: none;
      &:hover {
        transform: none;
      }
  `}
  ${props =>
    props.isFullWidth &&
    `
      white-space: pre-line;
      width: 100%;
  `}

  ${props =>
    props.containsIcon &&
    `
      svg {
        display: inline-block;
      }
  `}

  ${props =>
    props.containsIconRounded &&
    `
      border-radius: 100% !important;
      border: 2px solid ${color.lightest};
      background: ${color.transparent};
      color: ${color.lightest};

      svg {
        display: block;
        margin: 0;
        vertical-align: middle;
        display: flex;
      }

      span {
        vertical-align: middle;
        display: flex;
      }

      padding: ${props.size === SIZES.SMALL ? '7' : '12'}px;
  `}
`;

const PrimaryButton = styled.a`
  color: ${({ theme }) => theme.tertiaryPrimary};
  box-shadow: ${({ theme }) => theme.tertiaryShadowOutline};
  border: ${({ theme }) => theme.tertiaryBorder};

  svg {
    fill: ${({ theme }) => theme.primary};
  }
`;

const DarkButton = styled.a`
  color: ${({ theme }) => theme.darkText};
  box-shadow: ${({ theme }) => theme.darkShadowOutline};
  border: ${({ theme }) => theme.darkBorder};

  svg {
    fill: ${({ theme }) => theme.primary};
  }
`;

const PrimaryDarkTertiaryButton = styled.a`
  color: ${({ theme }) => theme.tertiaryPrimary};
  box-shadow: ${({ theme }) => theme.tertiaryShadowOutline};
  border: ${({ theme }) => theme.tertiaryBorder};

  svg {
    fill: ${({ theme }) => theme.primaryDarkTertiary};
  }
`;

const ButtonLink = StyledButton.withComponent('a');
const Primary = StyledButton.withComponent(PrimaryButton);
const Dark = StyledButton.withComponent(DarkButton);
const PrimaryDarkTertiary = StyledButton.withComponent(PrimaryDarkTertiaryButton);

const applyStyle = ButtonWrapper => {
  return (
    ButtonWrapper &&
    StyledButton.withComponent(({ containsIcon, isLoading, isFullWidth, textLinkDark, ...rest }) => (
      <ButtonWrapper {...rest} />
    ))
  );
};

export function Button({
  isDarkButton,
  isPrimaryButton,
  isPrimaryDarkTertiaryButton,
  isFullWidth,
  isLoading,
  containsIcon,
  containsIconRounded,
  loadingText,
  isLink,
  children,
  ButtonWrapper,
  ...props
})

{
  const buttonInner = (
    <Fragment>
      {children}
    </Fragment>
  );

  const StyledButtonWrapper = React.useMemo(() => applyStyle(ButtonWrapper), [ButtonWrapper]);

  let SelectedButton = StyledButton;
  if (ButtonWrapper) {
    SelectedButton = StyledButtonWrapper;
  }
  if (isLink) {
    SelectedButton = ButtonLink;
  }
  if (isDarkButton) {
    SelectedButton = Dark;
  }
  if (isPrimaryButton) {
    SelectedButton = Primary;
  }
  if (isPrimaryDarkTertiaryButton) {
    SelectedButton = PrimaryDarkTertiary;
  }

  //console.log(props);
  return (
    <SelectedButton onClick={() => (props.href) || (props.to)} isLoading={isLoading} containsIconRounded={containsIconRounded} containsIcon={containsIcon} isFullWidth={isFullWidth} {...props}>
      <ButtonText>{buttonInner}</ButtonText>
    </SelectedButton>
  );
}

Button.propTypes = {
  /**
   When a button is in the loading state you can supply custom text
  */
  loadingText: PropTypes.node,
  /**
   Buttons that have hrefs should use <a> instead of <button>
  */
  to: PropTypes.node,
  isLink: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  /**
   Prevents users from clicking on a button multiple times (for things like payment forms)
  */
  isUnclickable: PropTypes.bool,
  /**
   Buttons with icons by themselves have a circular shape
  */
  containsIcon: PropTypes.bool,
  containsIconRounded: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
  ButtonWrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

Button.defaultProps = {
  loadingText: null,
  isLink: true,
  isDisabled: false,
  isUnclickable: false,
  containsIcon: false,
  size: SIZES.MEDIUM,
  ButtonWrapper: undefined,
};