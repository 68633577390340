import React, { Fragment } from 'react';
//import styled from 'styled-components';
// Components
import Callout from '../../components/Callout/index';
import Wysiwyg from "../../components/Wysiwyg"
// Atoms
import { Heading } from '../../atoms/typography/headings'
// Globals
import { InnerStack } from '../../global/grid'
import AddButton from '../../components/AddButton';

const LefthandTextColumn = ({ title, subTitle, text, addButton, button, addCallout, calloutTitle, calloutLinks, align }) => {
    return (
        <Fragment>
            <Heading isH2={true} align={align}>{title}</Heading>  
                <InnerStack>
                { subTitle && <Heading isH3={true} align={align}>{subTitle}</Heading>}
                
                {text && ((
                    <Wysiwyg
                        text={text}
                    />
                ))}
                <AddButton
                    addButton={addButton}
                    button={button}
                    isPrimaryButton={true}
                />
                <Callout 
                    addCallout={addCallout}
                    calloutTitle={calloutTitle}
                    calloutLinks={calloutLinks}
                />
            </InnerStack>
        </Fragment>
    );
}

export default LefthandTextColumn;
