import React, { Fragment } from 'react';
import styled from 'styled-components';
import { color, device, box, elevate } from '../../global/theme';
import { easing } from '../../global/animations';

const Text = styled.span`
  display: inline-block;
`;

export const CardTitles = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin-top: 1.5rem;

    @media ${device.tablet} {
        margin-left: 1.5rem;
    }
`;

const StyledCard = styled.div`
  width: 100%;

  ${props =>
    props.isFilled &&
    `
      background-color: ${color.tertiary};
  `}

  ${props =>
    props.isHoverable &&
    `
    transition: ${easing.card};

    &:hover {
      box-shadow: ${elevate.elevate3};
    }

    &:active {
      box-shadow: ${elevate.elevate3};
    }

    &:focus {
      box-shadow: ${elevate.elevate3};
    }

    &:focus:hover {
      box-shadow: ${elevate.elevate3};
    }
  `}

  ${props =>
    props.hasNoPadding &&
    `
      padding: 0 !important;
  `}

  ${Text} {
      transform: scale3d(1,1,1) translate3d(0,0,0);
      transition: transform 700ms ${easing.rubber};
      opacity: 1;
  }
`;

const applyStyle = CardWrapper => {
    return (
        CardWrapper &&
        StyledCard.withComponent(({ ...rest }) => (
        <CardWrapper {...rest} />
      ))
    );
};

const TertiaryCard = styled.div`
  color: ${({ theme }) => theme.text};
  background-color: ${color.transparent};
  padding: ${box.halfGlobalPadding};
  border: ${({ theme }) => theme.tertiaryBorder};
  box-shadow: ${({ theme }) => theme.tertiaryShadow};
  border-radius: 8px;
`;

const TertiaryFilledCard = styled.div`
  color: ${({ theme }) => theme.darkText};
  background-color: ${({ theme }) => theme.tertiaryFilledCard};
  border: ${({ theme }) => theme.tertiaryBorder};
  box-shadow: ${({ theme }) => theme.tertiaryShadowOutline};
  padding: ${box.halfGlobalPadding};
  border-radius: 8px;
`;

const SecondaryFilledCard = styled.div`
  color: ${({ theme }) => theme.primaryDarkestTertiary};
  background-color: ${({ theme }) => theme.secondaryFilledCard};
  border: ${({ theme }) => theme.secondaryCard};
  box-shadow: ${({ theme }) => theme.secondaryShadowOutline};
  padding: ${box.halfGlobalPadding};
  border-radius: 8px;

  svg {
    fill: ${({ theme }) => theme.primaryDarkestTertiary};
  }
`;

const Tertiary = StyledCard.withComponent(TertiaryCard);
const TertiaryFilled = StyledCard.withComponent(TertiaryFilledCard);
const SecondaryFilled = StyledCard.withComponent(SecondaryFilledCard);

export function Card({
    isTertiaryCard,
    isTertiaryFilledCard,
    isSecondaryFilledCard,
    isHoverable,
    children,
    CardWrapper,
    hasNoPadding,
    ...props
})

{
  const cardInner = (
    <Fragment>
      {children}
    </Fragment>
  );
  const StyledCardWrapper = React.useMemo(() => applyStyle(CardWrapper), [CardWrapper]);

  let SelectedCard = StyledCard;
  if (CardWrapper) {
      SelectedCard = StyledCardWrapper;
  }
  if (isTertiaryCard) {
      SelectedCard = Tertiary;
  }
  if (isTertiaryFilledCard) {
      SelectedCard = TertiaryFilled;
  }
  if (isSecondaryFilledCard) {
      SelectedCard = SecondaryFilled;
  }
  
  //console.log(props);
  return (
      <SelectedCard isHoverable={isHoverable} hasNoPadding={hasNoPadding} {...props}>
        {cardInner}
      </SelectedCard>
  );
}
