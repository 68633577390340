import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Heading, P, H1, H2, H3, H4, H5, H6 } from '../atoms/typography/headings'
import { device, typography } from '../global/theme';

const StyledInnerStack = styled.span`
  display: block;
  padding-right: 0;

  @media ${device.mobileL} {
    padding-right: 4.5rem;
  }

  > * + * {
     margin-top: 1.5rem;
  }
`;

const StyledHeading = styled(Heading)`
    color: ${props => props.textColor};
    font-family: ${typography.type.secondary};

    p {
      ${P.componentStyle.rules};
    }

    strong {
      ${P.componentStyle.rules};
      font-weight: ${typography.weight.bold};
    }

    em {
      ${P.componentStyle.rules};
      font-style: italic;
    }

    h1 {
      ${H1.componentStyle.rules};
    }

    h2 {
      ${H2.componentStyle.rules};
    }

    h3 {
      ${H3.componentStyle.rules};
    }

    h4 {
      ${H4.componentStyle.rules};
    }

    h5 {
      ${H5.componentStyle.rules};
    }

    h6 {
      ${H6.componentStyle.rules};
    }
`;

const Wysiwyg = ({ text, textColor, textColumn }) => {
    return (
      <Fragment>
        { text && ((
          <StyledHeading color={textColor}>
            <StyledInnerStack dangerouslySetInnerHTML={{__html: text}} />
          </StyledHeading>
        ))}
        
        {textColumn && ((
          <StyledHeading color={textColor}>
            <StyledInnerStack dangerouslySetInnerHTML={{__html: textColumn}} />
          </StyledHeading>
        ))}
      </Fragment>
    )
}

export default Wysiwyg