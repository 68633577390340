import React from "react"
import styled from 'styled-components';
import { StaticQuery, graphql } from "gatsby"
// Atoms
import { Icon } from "../atoms/icon/Icon"
// Theme
import { Column } from '../global/grid'
import { col, position } from '../global/theme'
import { easing, float } from "../global/animations";


/**
 * Define MenuItem fragment and get all primary menu items.
 */
const MENU_QUERY = graphql`

    fragment MenuItem on WPGraphQL_MenuItem {
        id
        label
        url
        title
        target
    }

    query GETSOCIALMENU {
        wpgraphql {
            menuItems(where: {location: SOCIAL}) {
                nodes {
                    ...MenuItem
                }
            }
            generalSettings {
                url
            }
        }
    }
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  transition: ${easing.theme};

  &:hover {
    animation-name: ${float};
    animation-duration: 0.5s;
    animation-iteration-count: 1;
  }
`;

const StyledSocialLink = styled.a`
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
    }
`;

const FooterMenu = () => {
  return (
    <StaticQuery
      query={MENU_QUERY}
      render={(data) => {
        if (data.wpgraphql.menuItems) {
          const menuItems = data.wpgraphql.menuItems.nodes
          const wordPressUrl = data.wpgraphql.generalSettings.url
          // console.log(menuItems)
          return (
            <Column flex justifyContent={position.center} xsWidth={col.twelve} mdWidth={col.three}>
              {
                menuItems &&
                menuItems.map((menuItem) => (
                  <StyledSocialLink key={menuItem.id} href={menuItem.url}>
                    <StyledIcon href={menuItem.url}  size={"50px"} icon={menuItem.label} fill={({ theme }) => theme.tertiaryPrimary} wordPressUrl={wordPressUrl}/>
                  </StyledSocialLink>
                ))
              }
            </Column>
          )
        }
        return null
      }}
    />
  )
}

export default FooterMenu
