import React from 'react';
import styled from 'styled-components'
import { Link } from "gatsby"
// Atoms
import SiteLogo from '../global/graphics/SiteLogo';
import { Row } from '../global/grid';
import { box, color, device } from '../global/theme';
import { easing } from '../global/animations';

const StyledRow = styled(Row)`

  visibility: ${props => props.isScrolled ? "hidden" : "visible"};
  transform: ${props => props.isScrolled ? "translateY(-200px)" : " "};
  height: auto;
  margin-bottom: ${props => props.isScrolled ? "0 !important" : box.halfGlobalMargin};
  transition: ${easing.theme};

  @media ${device.mobileL} {
    margin-bottom: ${box.globalMargin};
    height: ${props => props.isScrolled ? "0" : "6rem"};
  }
`;

const Logo = ({ siteTitle, isScrolled }) => {
  // console.log(isScrolled)
    return (
        <StyledRow isScrolled={isScrolled}>
            <Link to="/">
              <SiteLogo fill={color.secondary} isScrolled={isScrolled}/>
            </Link>
        </StyledRow>
    );
}

export default Logo;
