import React from 'react';
import { Burger } from './style'

const BurgerMenu = ({ openNav, setOpenNav }) => {
  return (
      <Burger openNav={openNav} onClick={() => setOpenNav(!openNav)}>
        <div />
        <div />
        <div />
      </Burger>
  );
}

export default BurgerMenu