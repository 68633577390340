import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import "typeface-montserrat";
import { flexUnit } from '../../global/mixin.js';
import { typography } from '../../global/theme';
  
const ALIGN = {
    // left is set as default
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right'
};

/**
* Headings
*/

const StyledHeading = styled.p`
  text-align: left;
  color: ${props => props.color};

  ${props =>
    props.capitalise &&
    `
      text-transform: uppercase
  `}

  /**
  * Alignment
  */

  ${props =>
    props.align === ALIGN.CENTER &&
    `
    text-align: center;
  `}

  ${props =>
    props.align === ALIGN.RIGHT &&
    `
    text-align: right;
  `}
`;

/**
* Wrappers to change the heading hierarchy
* Use case: isH2={ true } 
*/
export const H1 = styled.h1`
  ${flexUnit(8.25, 18, 76, 'vw', 'font-size')};
  font-weight: ${ typography.weight.bold };
  color: ${({ theme }) => theme.text};
  font-family: ${ typography.type.secondary };
  letter-spacing: -1;
  line-height: 1;
`;

export const H2 = styled.h2`
  ${flexUnit(8, 22, 50, 'vw', 'font-size')};
  font-family: ${ typography.type.secondary };
  color: ${({ theme }) => theme.text};
  letter-spacing: 1px;
  line-height: 1.25em;
`;

export const H3 = styled.h3`
  ${flexUnit(5, 19, 30, 'vw', 'font-size')};
  font-weight: ${ typography.weight.bold };
  font-family: ${ typography.type.primary};
  color: ${({ theme }) => theme.text};
  letter-spacing: -1;
  text-align: left;
`;

export const H4 = styled.h4`
  ${flexUnit(2, 24, 34, 'vw', 'font-size')};
  font-family: ${ typography.type.secondary };
  font-weight: ${ typography.weight.bold };
  line-height: 1.25em;
`;

export const H5 = styled.h5`
  ${flexUnit(6, 9, 18, 'vw', 'font-size')};
  color: ${({ theme }) => theme.text};
  font-weight: 300;
  letter-spacing: -1;
  line-height: 1.75rem;
`;

export const H6 = styled.h6`
  ${flexUnit(6, 8, 16, 'vw', 'font-size')};
  font-weight: ${ typography.weight.bold };
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.2em;
`;

export const Blockquote = styled.blockquote`
  ${flexUnit(6, 10, 24, 'vw', 'font-size')};
  font-weight: ${ typography.weight.semibold };
  letter-spacing: -1;
  line-height: 1.25em;
  margin-left: 0;
`;

export const Citation = styled.cite`
  ${flexUnit(8, 13, 18, 'vw', 'font-size')};
  font-weight: ${ typography.weight.regular };
  line-height: 1.3rem;
`;

export const P = styled.p`
  ${flexUnit(8, 13, 21, 'vw', 'font-size')};
  font-weight: ${props => props.bold ? props.bold : typography.weight.light };
  font-family: ${ typography.type.primary};
  line-height: 1.3em;
`;

export const ParaSmall = styled.p`
  ${flexUnit(4, 10, 19, 'vw', 'font-size')};
  font-weight: ${props => props.bold ? props.bold : typography.weight.regular };
  line-height: 1.3em;
`;

export const ParaLarge = styled.p`
  ${flexUnit(9, 22, 46, 'vw', 'font-size')};
  font-weight: ${ typography.weight.bold };
  line-height: 1.3em;
`;

const Heading1 = StyledHeading.withComponent(H1);
const Heading2 = StyledHeading.withComponent(H2);
const Heading3 = StyledHeading.withComponent(H3);
const Heading4 = StyledHeading.withComponent(H4);
const Heading5 = StyledHeading.withComponent(H5);
const Heading6 = StyledHeading.withComponent(H6);
const Quote = StyledHeading.withComponent(Blockquote);
const Cite = StyledHeading.withComponent(Citation);
const Para = StyledHeading.withComponent(P);
const PSmall = StyledHeading.withComponent(ParaSmall);
const PLarge = StyledHeading.withComponent(ParaLarge);

const applyStyle = HeadingWrapper => {
  return (
      HeadingWrapper &&
      StyledHeading.withComponent(({ capitalise, ...rest }) => (
      // Rest used to include all the 'rest' of the props, not rename props:
      // https://stackoverflow.com/a/43484565
      <HeadingWrapper {...rest} />
    ))
  );
};
  
  export function Heading({
    capitalise,
    children,
    isH1,
    isH2,
    isH3,
    isH4,
    isH5,
    isH6,
    isQuote,
    isCite,
    isP,
    isPSmall,
    isPLarge,
    HeadingWrapper,
    ...props
  })
  
  {
    const headingInner = (
      <Fragment>
        {children}
      </Fragment>
    );
  
    const StyledHeadingWrapper = React.useMemo(() => applyStyle(HeadingWrapper), [HeadingWrapper]);
  
    let SelectedHeading = StyledHeading;

    if (HeadingWrapper) {
        SelectedHeading = StyledHeadingWrapper;
    }
    if (isH1) {
      SelectedHeading = Heading1;
    }
    if (isH2) {
      SelectedHeading = Heading2;
    }
    if (isH3) {
      SelectedHeading = Heading3;
    }
    if (isH4) {
      SelectedHeading = Heading4;
    }
    if (isH5) {
      SelectedHeading = Heading5;
    }
    if (isH6) {
      SelectedHeading = Heading6;
    }
    if (isQuote) {
      SelectedHeading = Quote;
    }
    if (isCite) {
      SelectedHeading = Cite;
    }
    if (isP) {
      SelectedHeading = Para;
    }
    if (isPSmall) {
      SelectedHeading = PSmall;
    }
    if (isPLarge) {
      SelectedHeading = PLarge;
    }

    //console.log(props);
    return (
      <SelectedHeading {...props}>
        {headingInner}
      </SelectedHeading>
    );
  }
  
  Heading.propTypes = {
    children: PropTypes.node,
    capatilise: PropTypes.bool,
    align: PropTypes.oneOf(Object.values(ALIGN)),
    HeadingWrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  };
  
  Heading.defaultProps = {
    capatilise: false,
    align: ALIGN.LEFT,
    HeadingWrapper: undefined,
  };
  