import styled from 'styled-components';
// Atoms
import { Button } from '../../atoms/button/Button';
// Theme
import { Container, Row } from '../../global/grid'
import { device } from '../../global/theme';


const StyledContainer= styled(Container)`
    margin-top: 6rem;
`;

const StyledRow = styled(Row)`
    justify-content: space-between;

    @media ${device.mobileL} {
        justify-content: unset;
    }
`;

const StyledButton = styled(Button)`
    margin-top: 1.5rem;
    
    @media ${device.mobileL} {
        margin-right: 1.5rem;
        margin-top: 1.5rem;
        justify-content: space-between;
    }
`;

export { StyledContainer, StyledRow, StyledButton }