import styled from 'styled-components'
import { size} from '../../global/theme'

const StyledDivider = styled.span`
  position: relative;
  display: block;
  margin: 0 auto;
  height: 2px;
  width: 100%;
  max-width: ${size.laptopL};
  background: ${({ theme }) => theme.divider};
`;

export { StyledDivider }
