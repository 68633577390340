import React from "react"
import styled from 'styled-components';
//import UniversalLink from "./UniversalLink"
import { Link } from "gatsby";
import { flexUnit } from '../global/mixin.js';
import { typography, box } from '../global/theme';
import { easing } from '../global/animations';

const MenuLink = styled(Link)`
    position: relative;
    text-transform: uppercase;
    ${flexUnit(3, 8, 14, 'vw', 'font-size')};
    color: ${({ theme }) => theme.text};
    font-family: ${ typography.type.primary };
    font-weight: ${ typography.weight.bold };
    text-decoration: none;
    margin-right: ${box.globalmargin};
    letter-spacing: 1.4px;
    line-height: 16px;

    &::after {
      content: " ";
      display: inline-block;
      width: 0px;
      height: 10px;
      transform: scaleX(0);
      position: absolute;
      bottom: -3.7rem;
      left: 50%;
      border-radius: 50%;
      background: ${({ theme }) => theme.lightDark};
      transition: all 0.3s ${easing.rubber};
    }

    &:hover::after {
      content: " ";
      display: inline-block;
      width: 10px;
      height: 10px;
      transform: scaleX(1);
      position: absolute;
      bottom: -15px;
      left: 45%;
      border-radius: 50%;
      background: ${({ theme }) => theme.hover};
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.active {
      &:after {
        content: " ";
        display: inline-block;
        width: 10px;
        height: 10px;
        transform: scaleX(1);
        position: absolute;
        bottom: -15px;
        left: 45%;
        border-radius: 50%;
        background: ${({ theme }) => theme.hover};
      }
    }

    &:hover {
      text-decoration: none;
    }
`;

const MenuItem = ({ menuItem, openNav, setOpenNav }) => {
  const getSlug = menuItem.connectedObject.slug;
  const url = '/' + getSlug;
  // console.log(menuItem)
  return (
    <MenuLink
      onClick={() => setOpenNav(!openNav)}
      to={url}
      activeClassName="active"
    >
      {menuItem.label}
    </MenuLink>
  )
}

export default MenuItem
