import React from 'react';
import styled, { css } from 'styled-components';
import { device, box, size } from './theme';
import { easing } from './animations';


export const Wrapper = styled.div`
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 ${ box.halfGlobalPadding };
    transition: ${easing.theme};

    @media ${device.tablet} {
        padding: 0 ${box.globalPadding};
    }

    @media ${device.laptop} {
        padding: 0 ${box.globalPadding};
    }

    @media ${device.laptopL} {
        padding:  0;
        max-width: ${size.laptopL}
    }
`;

export const SmallStack = styled.div`
    & > * + * {
        margin-top: 1.5rem;
    }
`;

export const InnerStack = styled.div`
    & > * + * {
        margin-top: 1.5rem;
    }

    @media ${device.mobileL} {
        & > * + * {
            margin-top: 3rem;
        }
    }
`;

export const Container  = styled.div`
    position: relative;
    padding: 0;

    @media ${device.tablet} {
        padding: 0 ${box.globalPadding};
    }

    @media ${device.laptop} {
        padding: 0 ${box.globalPadding};
    }

    @media ${device.laptopL} {
        padding: 0 calc(${box.globalPadding} * 2);
    }
`;

/**
 *
 * CSS Grid layout
 *
 */

export const CSSGrid = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
  padding-bottom: ${box.globalPadding};
`;

const withGrid = Component => styled(CSSGrid)`
    height: ${props => props.height};
    width: ${props => props.width};

    @media ${device.mobileM} {
        padding-bottom: ${box.globalPadding};
    }

    @media ${device.laptopL} {
        padding-bottom: calc(${box.globalPadding} + 3rem);
    }

    @media ${device.desktop} {
        padding-bottom: calc(${box.doubleGlobalPadding} + 3rem);
    }
`;

export const Grid = withGrid(({ className, children }) =>
  React.cloneElement(children, { className })
);

/**
 *
 * Grid Row
 * Use prop attributes to customise properties
 * 
 * Usage: <Row start center></Row>
 * 
 */

export const GridRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    flex-flow: ${props => props.direction};
    display: ${props => props.display};
    align-items: ${props => props.alignItems};
    align-self: ${props => props.alignSelf};
    justify-content: ${props => props.justifyContent};
    justify-items: ${props => props.justifyItems};

    ${props => props.gutters && css`
        padding-right: 0;
        padding-left: 0;

        @media ${device.mobileL} {
            padding-right: ${ box.halfGutterWidth };
            padding-left: ${ box.halfGutterWidth };
        }
    `}

    ${props => props.innerStack && css`
       & > * + * {
           margin-top: 3rem;
       }
    `}

    ${props => props.paddedRow && css`
        padding: ${box.globalPadding} 0;

    `}
`;

const withRow = Component => styled(GridRow)`
    height: ${props => props.height};
    width: ${props => props.width};
`;

export const Row = withRow(({ className, children }) =>
  React.cloneElement(children, { className })
);

/**
 *
 * Grid Column
 * Use position object from theme.js to customise positioning
 * ex: justifyContent={position.start}
 * 
 * Use the col object from theme.js to customise column width
 * ex: smWidth={col.eight}
 * 
 * Usage: <Column alignItems={position.center} xsWidth={col.twelve}></Column>
 * 
 */

export const Col = styled.div`
    flex: 0 0 auto;
    flex-flow: ${props => props.direction};
    position: ${props => props.position};
    align-items: ${props => props.alignItems};
    align-self: ${props => props.alignSelf};
    justify-content: ${props => props.justifyContent};
    justify-items: ${props => props.justifyItems};

    ${props => props.flex && css`
        display: flex;
    `}
    
    ${props => props.spacedColumn && css`
        margin: ${box.halfGlobalPadding} 0;
        transition: ${easing.theme};

        @media ${device.mobileL} {
            margin: ${box.globalPadding} 0;
        }

        @media ${device.laptopL} {
            margin: ${box.globalPadding}  0;
        }

        @media ${device.desktop} {
            margin: calc(${box.globalPadding} * 2)  0;
        }
    `}

    ${props => props.gutters && css`
        padding-right: 0;
        padding-left: 0;

        @media ${device.mobileL} {
            padding-right: ${ box.halfGutterWidth };
            padding-left: ${ box.halfGutterWidth };
        }
    `}
`;

const withColumn = Component => styled(Col)`
    width: ${props => props.xsWidth};
    padding-top: ${props => props.xsPaddingTop};
    transition: ${easing.theme};

  @media ${device.mobileL} {
    flex-basis: ${props => props.smWidth};
    margin-left: ${props => props.smOffsetWidth};
    padding-top: ${props => props.smPaddingTop};
  }

  @media ${device.tablet} {
    flex-basis: ${props => props.mdWidth};
    margin-left: ${props => props.mdOffsetWidth};
    padding-top: ${props => props.mdPaddingTop};
  }

  @media ${device.laptop} {
    flex-basis: ${props => props.lgWidth};
    margin-left: ${props => props.lgOffsetWidth};
    padding-top: ${props => props.lgPaddingTop};
  }
`;

export const Column = withColumn(({ className, children }) =>
  React.cloneElement(children, { className })
);