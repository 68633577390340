import React from 'react';
//import styled from 'styled-components'
import { StaticQuery, graphql } from "gatsby"
// Components
// import FooterMenu from "./FooterMenu"
import SocialMenu from "./SocialMenu"
import Divider from './Divider/index';
// Atoms
// Globals
import { Column, Row, Wrapper } from '../global/grid'
import { col, position } from '../global/theme';
import LefthandTextColumn from '../layouts/TextBlock/LefthandTextColumn';
import TextBlockImage from '../layouts/TextBlock/TextBlockImage';

const FOOTER_QUERY = graphql`
  query SETTINGS {
      wpgraphql {
        themeFooterSettings {
            footerContent {
                calloutLinks {
                  fieldGroupName
                  calloutLink {
                    target
                    title
                    url
                  }
                }
                calloutTitle
                fieldGroupName
                subTitle
                title
                image {
                  sourceUrl
                  altText
                  imageFile {
                    childImageSharp {
                      fluid(pngQuality: 100, quality: 100, cropFocus: CENTER) {
                        ...GatsbyImageSharpFluid_tracedSVG
                      }
                    }
                  }
                }
              }
          }
        }
      }
`

const Footer = () => {
  return (
    <StaticQuery
      query={FOOTER_QUERY}
      render={(data) => {
        const title = data.wpgraphql.themeFooterSettings.footerContent.title
        const subTitle = data.wpgraphql.themeFooterSettings.footerContent.subTitle
        const image = data.wpgraphql.themeFooterSettings.footerContent.image
        const calloutTitle = data.wpgraphql.themeFooterSettings.footerContent.calloutTitle
        const calloutLinks = data.wpgraphql.themeFooterSettings.footerContent.calloutLinks
        // console.log(data);
        return (
          <footer>
            <Wrapper>
              <Row paddedRow>
                <Column selfStart xsWidth={col.twelve} mdWidth={col.six}>
                  <LefthandTextColumn
                    title={title}
                    subTitle={subTitle}
                    calloutTitle={calloutTitle}
                    calloutLinks={calloutLinks}
                    addCallout={true}
                  />
                </Column>
                <Column selfStart xsWidth={col.twelve} mdWidth={col.six}>
                  <TextBlockImage
                    addImage={true}
                    image={image}
                    mdWidth={col.six}
                    lgWidth={col.six}
                    imageSpacing="-3rem"
                  />
                </Column>
              </Row>
              <Divider/>
              <Row paddedRow justifyContent={position.center}>
                <Column xsWidth={col.twelve} mdWidth={col.six}>
                  <SocialMenu/>
                </Column>
              </Row>
              <Divider/>
            </Wrapper>
          </footer>
        )
      }}
    />
  )
}

export default Footer

// <StyledHeading isP={true} color="lightest">© {new Date().getFullYear()} The Shimeøn Clinic, Designed & Build by <UniversalLink to="http://buzzwordcreative.co.uk" color={color.lightest}>Buzzword</UniversalLink></StyledHeading>