// theme.js is the single point for all styles
// import 'typeface-montserrat';
export const color = {
    // Primary - Purple
    primaryLight: '#BAA3E3',
    primary: '#6C5498', 
    primaryDark: '#4D337C', 
    primaryDarkest: '#361F47',
    // Secondary - Pink
    secondary: '#FB9B60',
    // Tertiary - Turquoise
    tertiary: '#75DABF',
    // Quaternary - Orange
    quaternary: '#f0830c',
    // Monochrome
    lightest: '#E6E0E0',
    light: '#DFDFDF',
    mediumLight: '#C8C8C9',
    medium: '#A8A8A9',
    dark: '#7C7D7E',
    darkest: '#515153',
    black: '#252628',
    transparent: 'transparent',
    gradientLight: 'linear-gradient(90deg, rgba(54,31,71,1) 10%, rgba(54,31,71,1) 10%, rgba(251,155,96,1) 10%)',
    gradientDark: 'linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 10%, rgba(251,155,96,1) 10%)'
};

// theme.js
export const lightTheme = {
  // Use this for lightest / darkest
  polar: color.primaryDarkest,
  primarySecondary: color.primary,
  primaryTertiary: color.primary,
  primaryDarkSecondary: color.primaryDark,
  primaryDarkTertiary: color.primaryDark,
  primaryDarkestTertiary: color.primaryDarkest,
  secondaryPrimary: color.secondary,
  tertiaryPrimaryDarkest: color.tertiary,
  tertiaryPrimary: color.tertiary,
  tertiarySecondary: color.tertiary,
  hover: color.secondary,
  text: color.lightest,
  divider: color.secondary,
  buttonPrimary: color.secondary,
  buttonPrimaryBg: color.primaryDark,
  darkBorder: `2px solid ${color.primaryDarkest}`,
  darkText: color.primaryDarkest, 
  darkShadow: `10px 10px 0px -1px ${color.primaryDarkest}`,
  darkShadowOutline: `10px 10px 0px -1px ${color.primaryDarkest}, 10px 10px 0px 1px ${color.primaryDarkest}`,
  tertiaryBorder: `2px solid ${color.tertiary}`,
  tertiaryFilledCard: color.tertiary, 
  tertiaryShadow: `10px 10px 0px -1px ${color.tertiary}`,
  tertiaryShadowOutline: `10px 10px 0px -1px ${color.primaryDarkest}, 10px 10px 0px 1px ${color.tertiary}`,
  secondaryCard: `2px solid ${color.secondary}`,
  secondaryFilledCard: color.secondary, 
  secondaryShadow: `10px 10px 0px -1px ${color.secondary}`,
  secondaryShadowOutline: `10px 10px 0px -1px ${color.primaryDarkest}, 10px 10px 0px 1px ${color.secondary}`,
  gradient: 'linear-gradient(#39598A, #79D7ED)',
}

export const darkTheme = {
  // Use this for lightest / darkest
  polar: color.lightest,
  primarySecondary: color.secondary,
  primaryDarkSecondary: color.secondary,
  primaryTertiary: color.tertiary,
  primaryDarkTertiary: color.tertiary,
  primaryDarkestTertiary: color.tertiary,
  secondaryPrimary: color.primary,
  tertiaryPrimaryDarkest: color.primaryDarkest,
  tertiaryPrimary: color.primary,
  tertiarySecondary: color.secondary,
  hover: color.secondary,
  text: color.darkest,
  divider: color.secondary,
  buttonPrimary: color.tertiary,
  buttonPrimaryBg: color.primary,
  darkBorder: `2px solid ${color.tertiary}`,
  darkText: color.tertiary, 
  darkShadow: `10px 10px 0px -1px ${color.tertiary}`,
  darkShadowOutline: `10px 10px 0px -1px ${color.tertiary}, 10px 10px 0px 1px ${color.tertiary}`,
  secondaryCard: `2px solid ${color.primaryDark}`,
  secondaryFilledCard: color.tertiary, 
  secondaryShadow: `10px 10px 0px -1px ${color.primaryDark}`,
  secondaryShadowOutline: `10px 10px 0px -1px ${color.lightest}, 10px 10px 0px 1px ${color.primaryDark}`,
  tertiaryBorder: `2px solid ${color.primary}`, 
  tertiaryFilledCard: color.primary, 
  tertiaryShadow: `10px 10px 0px -1px ${color.primary}`,
  tertiaryShadowOutline: `10px 10px 0px -1px ${color.lightest}, 10px 10px 0px 1px ${color.primary}`,
  gradient: 'linear-gradient(#091236, #1E215D)',
}

export const col = {
    auto: '0 0 auto',
    one: '8.33333333%',
    two: '16.66666667%',
    three: '25%',
    four: '33.33333333%',
    five: '41.66666667%',
    six: '50%',
    seven: '58.33333333%',
    eight: '66.66666667%',
    nine: '75%',
    ten: '83.33333333%',
    eleven: '91.66666667%',
    twelve: '100%',
};

export const position = {
  relative: 'relative',
  absolute: 'absolute',
  fixed: 'fixed',
  sticky: 'sticky',
  overflow: 'overflow',
  flex: 'flex',
  grid: 'grid',
  column: 'column',
  row: 'row',
  rowReverse: 'row-reverse',
  columnReverse: 'column-reverse',
  nowrap: 'nowrap',
  wrap: 'wrap',
  wrapeReverse: 'wrap-reverse',
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  stretch: 'stretch',
  baseline: 'baseline',
  between: 'space-between',
  around: 'space-around',
  evenlt: 'space-evenly',
  first: '-1',
  last: '1',
  unset: 'unset',
}

export const box = {
  globalmargin: '3rem',
  halfGlobalmargin: '1.5rem',
  globalPadding: '3rem',
  doubleGlobalPadding: '6rem',
  halfGlobalPadding: '1.5rem',
  globalMargin: '3rem',
  halfGutterWidth: 'calc(1.5rem * 0.5)',
  gutterComp: 'calc((1rem * 0.5) * -1)',
  calculatedPadding: 'calc(3rem + 4vw)',
  halfCalculatedPadding: 'calc(3rem + 2vw)',
}

export const typography = {
    type: {
      primary: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      secondary: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      code: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
    },
    weight: {
      light: '300',
      regular: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
    size: {
      s1: '12',
      s2: '14',
      s3: '16',
      m1: '20',
      m2: '24',
      m3: '28',
      l1: '32',
      l2: '40',
      l3: '48',
      code: '90',
    },
    transform: {
      lowercase: 'lowercase',
      fullwidth: 'full-width',
      uppercase: 'uppercase',
      capitalize: 'capitalize',
    }
};

// Elevation
export const elevate = {
  elevate5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  elevate4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  elevate3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);',
  elevate2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  elevate1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
}

export const theme = {
    // Colours
    
    // Borders
    border: '2px solid #252628',
    borderDashed: '2px dashed #252628',
    // Layout globals
    gutterWidth: '1rem',
    outerMargin: '2rem',
    gutterComp: 'calc((1rem * 0.5) * -1)',
    halfGutterWidth:'calc(1rem * 0.5)',
    xsMin: '30',
    smMin: '48',
    mdMin: '64',
    lgMin: '75',
    containerSm: 'calc(48 + 1rem)',
    containerMd: 'calc(64 + 1rem)',
    containerLg: 'calc(75 + 1rem)',
    globalPadding: '2rem',
    halfGlobalPadding: '1rem',
    globalMargin: '48px auto 48px',
    globalShadow: '0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08)',

    // Transitions
    mqTransition: 'all 0.5s cubic-bezier(0.215, 0.610, 0.355, 1)',
    hoverTransition: 'all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)',

    // Font settings

    heading: ['52px', '42px', '32px','24px','20px','12px'],
    body: ['16px','18px'],
    label: '14px',
    weight: ['300','500','700'],
    align: ['left', 'center', 'right'],
    borderOptions: ['none', 'hidden', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset'],
    fontStyleOptions: ['italic', 'oblique', 'normal'],
    fontWeightOptions: ['normal', 'bold', 'bolder', 'lighter'],
    texttransform: ['lowercase', 'full-width', 'uppercase', 'capitalize'],
    // Device sizes
}

// Breakpoint sizes
export const size = {
    mobileS: '19rem', // 320px
    mobileM: '26.5625rem', // 425px
    mobileL: '41.875rem', // 670px
    tablet: '50rem', // 769px
    laptop: '64rem', // 1024px
    laptopL: '95rem', // 1440px
    desktop: '160rem', // 2560px
    maxWidth: '78rem'
}

// Media queries
export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
  };

  
  