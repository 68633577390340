import React, { Fragment } from 'react';
import styled from 'styled-components';
// Components
import BurgerMenu from "./BurgerMenu/";
import MenuItem from "./MenuItem"
// Global
import { color, box } from '../global/theme';
import { Row } from '../global/grid';
import Logo from './Logo';
import { Button } from '../atoms/button/Button';

const StyledMenu = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${color.primary};
  transform: ${({ openNav }) => openNav ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  width: 100vw;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: -1rem;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: -1;

  @media (max-width: 970px) {
      width: 100%;
    }

  li {
    text-transform: uppercase;
    padding: 0.5rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ${color.darkest};
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 970px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: ${color.dark}
    }
  }
`

const MenuInner = styled.div`
  
`;

const StyledButton = styled(Button)`
  margin-left: -3rem;
`;

const StyledRow = styled(Row)`
  padding: ${box.halfGlobalmargin};
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
  
const MobileMenu = ({ openNav, setOpenNav, menuItems, wordPressUrl, siteTitle, toggleTheme, theme }) => {
    return (
      <Fragment>
          <StyledRow>
            <Logo siteTitle={siteTitle} />
            <StyledButton size="small" onClick={toggleTheme}>{theme}</StyledButton>
            <BurgerMenu openNav={openNav} setOpenNav={setOpenNav}/>
          </StyledRow>
  
            <StyledMenu openNav={openNav}>
              <MenuInner>
                {
                menuItems &&
                menuItems.map((menuItem) => (
                  <li>
                    <MenuItem openNav={openNav} setOpenNav={setOpenNav} key={menuItem.id} menuItem={menuItem} wordPressUrl={wordPressUrl}/>
                  </li>
                ))
                }
              </MenuInner>
            </StyledMenu>
      </Fragment>
    );
}


export default MobileMenu