import React from 'react';
import styled from 'styled-components';
import { device} from '../theme';

const Svg = styled.svg`
    height: ${props => props.isScrolled ? "20" : "40"}px;
    fill: ${props => props.fill};
    transition: all 0.3s ease-in-out;
    margin-right: 3rem;
    
    @media ${device.tablet} {
        height: ${props => props.isScrolled ? "40" : "60"}px;
    }
`;

const SiteLogo = ({ fill, isScrolled }) => (
    <Svg viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title" fill={fill} isScrolled={isScrolled}>
        <title>Joe Worthington</title>
        <path fill={fill} d="M34.21,8.54A5.71,5.71,0,1,1,28.5,2.82,5.71,5.71,0,0,1,34.21,8.54ZM74.29,2.89a5.72,5.72,0,0,0-5.72,5.72V60a5.72,5.72,0,0,1-11.43,0V20a5.72,5.72,0,1,0-11.43,0V60a5.71,5.71,0,0,1-11.42,0V31.46a5.72,5.72,0,0,0-11.43,0V60a5.72,5.72,0,0,1-11.43,0V42.89A5.72,5.72,0,0,0,0,42.89V60A17.15,17.15,0,0,0,22.86,76.2v0a17.2,17.2,0,0,0,5.7-3.38,17.13,17.13,0,0,0,17.15,3.4v0a17.15,17.15,0,0,0,5.71-3.38,17.13,17.13,0,0,0,17.15,3.4v0A17.18,17.18,0,0,0,79,65.75,17,17,0,0,0,80,60V8.61A5.72,5.72,0,0,0,74.29,2.89Z"/>
        
    </Svg>
)

export default SiteLogo;

