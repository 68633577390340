import React, { Fragment } from 'react';
import styled from 'styled-components';
import FluidImage from '../../components/FluidImage'
import Caption from '../../components/Caption/index'

import { col, device, position } from '../../global/theme'
import { Column } from '../../global/grid'

// These counter the image positions with the padding set in the padded Row props in grid.js
const StyledColumn = styled(Column)`
    margin-bottom: ${props => props.noBottomSpace ? "-3rem" : ""} !important;
    margin-top: 3rem !important;

    @media ${device.desktop} {
        margin-bottom: ${props => props.noBottomSpace ? "-3rem" : ""} !important;
        margin-top: ${props => props.addTopSpace ? "6rem" : "0"} !important;
    }
`;

const TextBlockImage = ({ addImage, imageWidth, image, imageSpacing }) => {
    //console.log(imageSpacing);
    /* eslint eqeqeq: 0 */
    const noBottomSpace = imageSpacing == "-3rem"
    /* eslint eqeqeq: 0 */
    const addTopSpace = imageSpacing == "3rem"
    //console.log(image);
    return (
        <Fragment>
            {addImage &&  ((
                <StyledColumn xsWidth={col.twelve} mdWidth={imageWidth} lgWidth={imageWidth} noBottomSpace={noBottomSpace} addTopSpace={addTopSpace} alignSelf={position.end}>
                    <FluidImage image={image}/>
                    { image.caption && ((
                        <Caption caption={image} />
                    ))}
                </StyledColumn>
            ))}
        </Fragment>
    );
}

export default TextBlockImage;
