import styled from 'styled-components'
import React, { Fragment } from 'react'
import { Link } from "gatsby"
// Components
import MenuItem from "./MenuItem"
import SiteLogo from '../global/graphics/SiteLogo';
// Global
import { Column, Row } from '../global/grid';
import { color, position, col } from '../global/theme';
import { Button } from '../atoms/button/Button';

const StyledRow = styled(Row)`
  padding: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
`;


const DesktopMenu = ({ menuItems, wordPressUrl, toggleTheme, theme, isScrolled }) => {
  const logoColor = isScrolled ? color.primary : color.secondary;
  return (
    <Fragment>
      <StyledRow isScrolled={isScrolled} alignItems={position.center}>
        <Column xsWidth={col.twelve}>
          
          <Row justifyContent={position.between} alignItems={position.center}>
          <Row alignItems={position.center}>
            <Link to={"/"}>
              <SiteLogo isScrolled={isScrolled} fill={logoColor} />
            </Link>
              {
                menuItems &&
                menuItems.map((menuItem) => (
                  <MenuItem key={menuItem.id} menuItem={menuItem} wordPressUrl={wordPressUrl}/>
                ))
              }
              </Row>
              <Row>
                <Button size="small"  onClick={toggleTheme}>{theme}</Button>
              </Row>
          </Row>
        </Column>
      </StyledRow>
    </Fragment>
  );
}

export default DesktopMenu