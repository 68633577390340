import React from 'react';
// Atoms
import { Heading } from '../../atoms/typography/headings';
// Theme
import { StyledRow, StyledIcon } from './style';


const Caption = ({ caption }) => {
    console.log(caption);
    return (
        <StyledRow>
            <StyledIcon icon="arrowup" fill={({ theme }) => theme.text} /><Heading isP={true}><span dangerouslySetInnerHTML={{__html: caption.caption }} /></Heading>
        </StyledRow>
    );
}

export default Caption;
