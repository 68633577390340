import React, { Fragment } from "react"
import { StaticQuery, graphql } from "gatsby"
// Global utilities
import { useWindowWidth } from '../global/custom-hooks/windowWidth';

import MobileMenu from "./MobileMenu"
import DesktopMenu from "./DesktopMenu"

/**
 * Define MenuItem fragment and get all primary menu items.
 */
const MENU_QUERY = graphql`

    fragment MenuItem on WPGraphQL_MenuItem {
        id
        label
        url
        title
        target
    }

    query GETMAINMENU {
        wpgraphql {
            menuItems(where: {location: PRIMARY}) {
                nodes {
                    ...MenuItem
                    connectedObject {
                    ... on WPGraphQL_Page {
                      slug
                      id
                      themeSettings {
                        fieldGroupName
                        pageColor
                      }
                    }
                  }
                }
            }
            generalSettings {
                url
            }
        }
    }
`

const Menu = ({ openNav, setOpenNav, siteTitle, toggleTheme, theme, isScrolled }) => {
  const desktopMenu = DesktopMenu
  const mobileMenu = MobileMenu
  const ResponsiveNav = useWindowWidth() >= 670 ? desktopMenu : mobileMenu;
  return (
    <StaticQuery
      query={MENU_QUERY}
      render={(data) => {
        //console.log(data)
        if (data.wpgraphql.menuItems) {
          const menuItems = data.wpgraphql.menuItems.nodes
          const wordPressUrl = data.wpgraphql.generalSettings.url
          return (
            <Fragment>
              <ResponsiveNav 
                openNav={openNav} 
                setOpenNav={setOpenNav} 
                menuItems={menuItems}
                wordPressUrl={wordPressUrl}
                siteTitle={siteTitle}
                toggleTheme={toggleTheme}
                theme={theme}
                isScrolled={isScrolled}
              />
            </Fragment>
          )
        }
        return null
      }}
    />
  )
}

export default Menu

