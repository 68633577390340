import styled from 'styled-components';
// Atoms
import { Icon } from '../../atoms/icon/Icon';
// Theme
import {  Row } from '../../global/grid'
import { device } from '../../global/theme';

const StyledRow = styled(Row)`
    margin-top: 1.5rem;
`;

const StyledIcon = styled(Icon)`
    margin-right: 0.5rem;

    @media ${device.mobileL} {
        margin-right: 1.5rem;
    }
`;

export { StyledIcon, StyledRow };
