import React, { Fragment } from 'react';
import { StyledDivider }  from './style';

const Divider = ({ counterMargin }) => {
    return (
        <Fragment>
            <StyledDivider counterMargin={counterMargin} />
        </Fragment>
    );
}

export default Divider;
