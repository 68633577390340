import styled from 'styled-components'
// Theme
import { size, device, box } from '../../global/theme'
import { Wrapper } from '../../global/grid';

const SiteHeader = styled.header`
  position: relative;
  padding: 0;
  z-index: 99999;
  width: 100%;
  display: block;

  @media ${device.mobileL} {
    position: fixed;
    display: flex;
    justify-content: center;
    height: ${props => props.isScrolled ? "4rem" : "8rem"};
    top: 0;
    left: 0;
    padding: 0 ${box.globalMargin};;
    width: 100%;
    background: ${({ theme }) => theme.polar};
    transition: height 0.3s ease;
  }
`;

const SiteNavigation = styled.nav`
    position: relative;
    height: auto;
    padding: 0 1rem;
    transition: height 0.3s ease;

    @media ${device.mobileL} {
      height: ${props => props.isScrolled ? "4rem" : "8rem"};
      top: 0;
      left: 0;
      padding: 0;
      width: 100%;
      max-width: ${size.laptopL};
      background: ${({ theme }) => theme.polar};
      transition: height 0.3s ease;
    }
`;

const StyledWrapper = styled(Wrapper)`
  padding: 0;

  @media ${device.mobileL} {

  }
`;

export { SiteHeader, SiteNavigation, StyledWrapper }