/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider  } from 'styled-components';
import { lightTheme, darkTheme } from '../global/theme';
import { device } from '../global/theme';
import { useStaticQuery, graphql } from "gatsby"
import {useDarkTheme} from '../global/custom-hooks/useDarkTheme'
import Header from "./Header/index"
import Footer from "./Footer"
// import Footer from "./Footer"
import "./layout.css"
import { createGlobalStyle } from "styled-components"

// applies the `open` state variable to stop scrolling when mobile menu is active.
const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
    overflow: ${({ openNav }) => openNav ? `hidden` : ``};
    background: ${({ theme }) => theme.polar};
    color: ${({ theme }) => theme.text};
  }
  
    *:focus {
    outline: 0;
  }

  button,
  a {
    cursor: pointer;
    position: relative;
    color: ${({ theme }) => theme.tertiarySecondary};
    text-decoration: underline;
  }

  a {
    text-decoration: none;
  }  

  ul {
    list-style: none;
  }
`

// This is fixed header offset
const Main = styled.main`
  padding-top: 0;
  overflow: hidden;

  @media ${device.mobileL} {
    padding-top: 8rem;
  }
`;

function Layout ({ children, themeGeneralSettings }) {
  const node = useRef();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [ openNav, setOpenNav ] = useState(false);
  const [theme, toggleTheme, componentMounted] = useDarkTheme();

  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  if (!componentMounted) {
    return <div />
  };

  
  // console.log(theme)
  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyle openNav={openNav}/>
        <div ref={node}>
          <Header 
            siteTitle={data.site.siteMetadata.title}
            openNav={openNav}
            setOpenNav={setOpenNav}
            theme={theme} 
            toggleTheme={toggleTheme}
          />
        </div>
      <Main theme={theme}>
        {children}
      </Main>
      <Footer themeGeneralSettings={themeGeneralSettings}/>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
