import React, { Fragment } from 'react';
import { Button } from '../atoms/button/Button';


const AddButton = ({ button, isFullWidth, isPrimaryDarkTertiaryButton, isPrimaryButton }) => {
    if (button) {
        return (
            <Button
                isLink={true}
                href={button.url}
                isFullWidth={isFullWidth}
                isPrimaryDarkTertiaryButton={isPrimaryDarkTertiaryButton}
                isPrimaryButton={isPrimaryButton}
            >
                {button.title}
            </Button>
        );
        } else {
            return <Fragment></Fragment>;
    }
}

export default AddButton